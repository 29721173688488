<template>
  <v-navigation-drawer class="pa-3" clipped app color="navbar" v-if="!$vuetify.breakpoint.mobile">
    <div class="mt-8">
      <h3 class="ml-4 mb-2">{{ projectName }}</h3>
      <r-btn class="mr-2 mb-2" plain :to="{ name: 'RiskList' }" exact>
        <v-icon class="mr-2">mdi-page-next</v-icon>
        {{ $t('ProjectItems.Risk') }}
      </r-btn>
      <r-btn class="mr-2 mb-2" plain :to="{ name: 'RiskMatrix' }" exact>
        <v-icon class="mr-2">mdi-grid</v-icon>
        {{ $t('ProjectItems.RiskMatrix') }}
      </r-btn>
      <r-btn class="mr-2 mb-2" plain :to="{ name: 'RiskTriangle' }" exact>
        <v-icon class="mr-2">mdi-triangle-outline</v-icon>
        {{ $t('ProjectItems.RiskProfile') }}
      </r-btn>
      <r-btn class="mr-2 mb-2" plain :to="{ name: 'rapports' }" exact>
        <v-icon class="mr-2">mdi-post-outline</v-icon>
        {{ $t('ProjectItems.Reports') }}
      </r-btn>
      <r-btn class="mr-2 ml-1 mb-2" plain :to="{ name: 'ImpactLevel' }" exact>
        <v-icon class="mr-2">mdi-page-previous-outline</v-icon>
        {{ $t('ProjectItems.ImpactLevel') }}
      </r-btn>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
export default {
  name: 'LeftNavBar',
  computed: {
    ...mapGetters(['user', 'langList', 'isAdmin', 'darkMode', 'currentProjectId']),
    FrontPageUserItems() {
      const t = this.$t.bind(this);
      if (this.isAdmin) {
        const test = t('langs.FrontPageUserItems');
        test.push(t('langs.FrontPageAdminItems'));
        return test;
      }
      return t('langs.FrontPageUserItems');
    },
  },
  data: () => ({
    projectName: null,
  }),
  watch: {
    currentProjectId: {
      handler() {
        this.getProjectName();
      },
    },
  },
  methods: {
    async getProjectName() {
      let project = await apiService.getProjectById(this.currentProjectId);
      this.projectName = project.name;
    },
  },
};
</script>

<style>
.v-input__control .v-input__slot .v-select__slot > label {
  transform: translateY(-35px) scale(0.75) !important;
}
</style>
