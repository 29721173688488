<template>
  <div>
    <left-nav-bar />
    <router-view />
  </div>
</template>

<script>
//import ProjectHeader from '@/components/ProjectHeader.vue';
import LeftNavBar from '../components/LeftNavBar.vue';
export default {
  components: { LeftNavBar },
  name: 'Project',
};
</script>

<style></style>
